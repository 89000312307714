
import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import App from "./App";
import router from "./router/index";
import store from './store/Index';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'bootstrap';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import BlackDashboard from "./plugins/blackDashboard";
import i18n from "./i18n";
import "./registerServiceWorker";
import * as VueGoogleMaps from 'vue2-google-maps';
import jQuery from 'jquery';

window.$ = window.jQuery = jQuery;
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCk6Qet4uRb2EaiEoBgw-eOXedRMUuoudA',
    libraries: 'places, drawing',
  },
})
Vue.use(VueToast, {
  position: 'top-right',
});
Vue.use(VueSweetalert2);
Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
new Vue({
  render: (h) => h(App),
  store,
  router,
  i18n,
}).$mount("#app");
