<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
      <tr>
        <slot name="columns">
          <th v-for="column in columns" :key="column">{{ column }}</th>
        </slot>
        <th v-if="options > 1">Options</th>
      </tr>
    </thead>
    <tbody :class="tbodyClasses">
      <tr v-for="(item, index) in data" :key="index">
        <slot :row="item">
          <template v-for="(column, index) in columns">
            <td :key="index" v-if="hasValue(item, column)">
              {{ itemValue(item, column) }}
            </td>
          </template>
          <td v-if="options == 2">
            <a class="p-1 delete-button" @click="openDeleteModal(item)">
              <i class="fas fa-trash-alt" style="color: #bf584a;"></i> <!-- Icono de eliminar -->
            </a>
            <a class="p-1 edit-button ml-1" @click="openEditModal(item)">
              <i class="tim-icons icon-pencil" style="color: #58a5d1;"></i>
              <!-- Icono de editar -->
            </a>
          </td>
          <td v-if="options == 3">
            <a class="p-1 edit-button ml-1" @click="openEditModal(item)">
              <i class="tim-icons icon-pencil" style="color: #58a5d1;"></i>
              <!-- Icono de editar -->
            </a>
          </td>
        </slot>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { Network } from '../application/network.js';

export default {
  name: "base-table",
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: "Table columns",
    },
    data: {
      type: Array,
      default: () => [],
      description: "Table data",
    },
    options: {
      type: Number,
      default: 0
    },
    type: {
      type: String, // striped | hover
      default: "",
      description: "Whether table is striped or hover type",
    },
    theadClasses: {
      type: String,
      default: "",
      description: "<thead> css classes",
    },
    tbodyClasses: {
      type: String,
      default: "",
      description: "<tbody> css classes",
    },
  },
  data() {
    return {
      tok: this.$store.state.CSRFToken,
      tokenAuth: this.$store.state.token,
    }
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    openEditModal(item) {
      const id = item.id;
      const basePath = this.$route.path.split('/')[1]; // Obtiene la primera parte de la ruta
      this.$router.push({
        path: `/${basePath}/edit/${id}`,
        query: { id: item.id },
        params: { item: item.id }

      });
    },
    openDeleteModal(item) {
      this.$swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.isConfirmed) {
          const id = item.id;
          const basePath = this.$route.path.split('/')[1]; // Obtiene la primera parte de la ruta
          console.log(basePath)
          Network.delete(`${basePath}/${id}`, {
            headers: {
              Accept: 'aplication/json',
              'Authorization': 'Bearer ' + this.tokenAuth
            },
            withCredentials: true,
          }).then((response) => {
            console.log(response)
          }).catch((err) => {
            console.log(JSON.stringify(err))
            alert(JSON.stringify(err))
          })

          this.$router.push({ path: `/dashboard` });
          this.$swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success"
          });
        }
      });
    }
  },
};
</script>
<style scoped>
.delete-button,
.edit-button {
  transition: background-color 0.1s ease;
  /* Transición suave de color de fondo */
}

.delete-button:hover,
.edit-button:hover {
  background-color: #12305e38;
  opacity: 0.5;
  /* Color de fondo al pasar el cursor por encima */
  cursor: pointer;
  /* Cambia el cursor a una mano para indicar que es clickeable */
}
</style>
