<template>
    <div class="container-fluid vh-100 d-flex justify-content-center align-items-center" style="background-color: #FFFFFF;">
      <div class="card p-4 shadow" style="width: 600px; background-color: #133b5d; border-radius: 35px;">
        <img src="../assets/logo.png" alt="Logo" class="img-fluid mx-auto mb-4" style="width: 300px;">
        <h2 class="text-center mb-4" style="color: #FFFFFF;">Registro de Empresa</h2>
  
        <div v-if="errorMessage" class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>
  
        <form @submit.prevent="onSubmit">
          <div class="mb-3">
            <label for="companyName" class="form-label" style="color: #FFFFFF;">Nome da Empresa</label>
            <input
              type="text"
              class="form-control"
              id="companyName"
              v-model="data.name"
              required
              placeholder="Nome da empresa"
            />
            <div v-if="errors.name" class="text-danger">{{ errors.name }}</div>
          </div>
  
          <div class="mb-3">
            <label for="email" class="form-label" style="color: #FFFFFF;">Email</label>
            <input
              type="email"
              class="form-control"
              id="email"
              v-model="data.email"
              required
              placeholder="correo@deliveryforyou.com"
              autocomplete="email"
            />
            <div v-if="errors.email" class="text-danger">{{ errors.email }}</div>
          </div>
  
          <div class="mb-3">
            <label for="phone" class="form-label" style="color: #FFFFFF;">Telemovel</label>
            <input
              type="tel"
              class="form-control"
              id="phone"
              v-model="data.phone"
              required
              placeholder="Número de telemovel"
            />
            <div v-if="errors.phone" class="text-danger">{{ errors.phone }}</div>
          </div>
  
          <div class="mb-3">
            <label for="website" class="form-label" style="color: #FFFFFF;">Site</label>
            <input
              type="url"
              class="form-control"
              id="website"
              v-model="data.website"
              placeholder="https://ejemplo.com"
            />
            <!-- No validation error for optional field -->
          </div>
  
          <div class="mb-3">
            <label for="password" class="form-label" style="color: #FFFFFF;">Password</label>
            <input
              type="password"
              class="form-control"
              id="password"
              v-model="data.password"
              required
              placeholder="Password"
              autocomplete="new-password"
            />
            <div v-if="errors.password" class="text-danger">{{ errors.password }}</div>
          </div>
  
          <div class="mb-3">
            <label for="confirmPassword" class="form-label" style="color: #FFFFFF;">Confirmar Password</label>
            <input
              type="password"
              class="form-control"
              id="confirmPassword"
              v-model="confirmPassword"
              required
              placeholder="Confirmar password"
              autocomplete="new-password"
            />
            <div v-if="errors.confirmPassword" class="text-danger">{{ errors.confirmPassword }}</div>
          </div>
  
          <button type="submit" class="btn btn-success w-100">Registrar Empresa</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { Network, RegisterEndpoints } from '../application/network.js';
  
  export default {
    name: 'register',
    data() {
      return {
        data: {
          name: '',
          phone: '',
          email: '',
          password: '',
          website: '',
        },
        confirmPassword: '',
        errorMessage: '',
        errors: {}
      };
    },
    methods: {
      validateForm() {
        this.errors = {};
  
        // Validación del nombre de la empresa
        if (!this.data.name) {
          this.errors.name = "Nome da empresa é obrigatório.";
        }
  
        // Validación del email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!this.data.email) {
          this.errors.email = "Email é obrigatório.";
        } else if (!emailRegex.test(this.data.email)) {
          this.errors.email = "Por favor, insira um email válido.";
        }
  
        // Validación del teléfono
        if (!this.data.phone) {
          this.errors.phone = "Número de telemovel é obrigatório.";
        }
  
        // Validación de la contraseña
        if (!this.data.password) {
          this.errors.password = "Password é obrigatória.";
        } else if (this.data.password.length < 8) {
          this.errors.password = "A senha deve ter no mínimo 8 caracteres.";
        }
  
        // Validación de la confirmación de la contraseña
        if (!this.confirmPassword) {
          this.errors.confirmPassword = "Confirmação da password é obrigatória.";
        } else if (this.data.password !== this.confirmPassword) {
          this.errors.confirmPassword = "As senhas não coincidem.";
        }
  
        // Retornar si no hay errores
        return Object.keys(this.errors).length === 0;
      },
  
      async onSubmit() {
        if (this.validateForm()) {
          try {
            await Network.post(RegisterEndpoints.register, this.data);
            this.$router.push('/');
          } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
              this.errorMessage = err.response.data.message;
            } else {
              this.errorMessage = 'Ocurrió un error durante el registro. Por favor, intenta nuevamente.';
            }
          }
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .alert-danger {
    color: white;
    background-color: #e74c3c;
    border-color: #c0392b;
  }
  .form-control {
    border-color: #FFFFFF; 
    color:#000000;
    background-color: #ffff;
  }
  .form-control:focus {
    border-color: #FFFFFF; 
    color:#000000;
    background-color: #ffff;
  }
  .form-control::placeholder {
    color: #5d5d5d;
  }
  .text-danger {
    color: #e74c3c;
    font-size: 0.9em;
  }
  </style>
  