<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/dashboard" :name="$t('sidebar.dashboard')" icon="tim-icons icon-chart-pie-36"/>
        <sidebar-link to="/companies" :name="$t('sidebar.companies')" icon="tim-icons icon-bank 36" v-if="userRole !== 'driver'"/>
        <sidebar-link to="/drivers" :name="$t('sidebar.driver')" icon="tim-icons icon-badge" v-if="userRole === 'superAdmin'"/>
        <sidebar-link to="/customers" :name="$t('sidebar.customer')" icon="tim-icons icon-laptop" v-if="userRole !== 'driver'"/>
        <sidebar-link to="/slots" :name="$t('sidebar.calendar')" icon="tim-icons icon-notes" v-if="userRole === 'superAdmin'"/>
        <sidebar-link to="/delivery" :name="$t('sidebar.order')" icon="tim-icons icon-cart" v-if="userRole !== 'driver'"/>
        <sidebar-link to="/hours" :name="$t('sidebar.agen')" icon="tim-icons icon-time-alarm" v-if="userRole === 'driver'"/>
        <sidebar-link to="/driver/table" :name="$t('sidebar.gestionhorario')" icon="tim-icons icon-calendar-60" v-if="userRole === 'driver'"/>
        <sidebar-link to="/fees" :name="$t('sidebar.fee')" icon="tim-icons icon-align-center" v-if="userRole === 'superAdmin'"/>
        <sidebar-link to="/services" :name="$t('sidebar.service')" icon="tim-icons icon-bus-front-12" v-if="userRole === 'superAdmin'"/>
        <sidebar-link to="/driver_prices" :name="$t('sidebar.price-driver')" icon="tim-icons icon-money-coins" v-if="userRole === 'superAdmin'"/>
        <!--<sidebar-link to="/apidelivery/showinfo" :name="$t('sidebar.ApiD')" icon="tim-icons icon-notes" v-if="userRole === 'superAdmin'"/>-->
        <sidebar-link to="/slots/index" :name="$t('sidebar.gestionhorario')" icon="tim-icons icon-notes" v-if="userRole === 'superAdmin'"/>
        <sidebar-link to="/driver_index" :name="$t('sidebar.DriverIndex')" icon="tim-icons icon-button-power" v-if="userRole === 'roberto'"/>
        <sidebar-link to="/apidelivery/calculate_statics" :name="$t('sidebar.Apitotal')" icon="tim-icons icon-paper" v-if="userRole === 'superAdmin'"/>
        <sidebar-link to="/driver_historic" :name="$t('sidebar.driver_historic')" icon="tim-icons icon-bullet-list-67" v-if="userRole === 'driver'"/>
        <sidebar-link to="/profile" :name="$t('sidebar.userProfile')" icon="tim-icons icon-single-02" />
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>  
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";  
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
  },
  computed: {
    userRole() {
      return this.$store.getters.role;
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
