<template>
  <footer class="footer">
    <div class="container-fluid">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" href="https://www.deliveryforyou.pt/termosdeutilizacao">
            User´s Terms </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.deliveryforyou.pt/politica-de-privacidade">
            Privacy Polity
          </a>
        </li>
      </ul>
      <div class="copyright">
        © {{ year }}
        <a href="https://www.deliveryforyou.pt" target="_blank" rel="noopener">Delivery for you</a>&nbsp;
        <a href="" target="_blank" rel="noopener"></a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
